<template>
  <footer>
    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2527.611164414549!2d14.526168299999997!3d50.69004219999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47096c02a6cd5b2b%3A0x35788dc5acb71092!2sKozlovna%20Sklen%C3%ADk!5e0!3m2!1scs!2scz!4v1688156047344!5m2!1scs!2scz" width="600" height="450" style="border:0; position: absolute;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->

    <div class="container">
      <div class="left">
      </div>
      <div class="right">
        <p>© 2025 Kozlovna Skleník. All rights reserved<br>
        Provided by: <a href="https://www.linkedin.com/in/tom%C3%A1%C5%A1-patol%C3%A1n-76a932233/">Tomáš Patolán</a>
        </p>
      </div>
    </div>
    
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>

footer{
    padding-top: 90em
  };


iframe{
  opacity: 50%;
  position: absolute;
  left: 5%;
  margin-top: -300px;
  width: 500px;
}
.option {
      color: white;
      text-decoration: underline;
      display: flex;
      margin-left: 1500px;
      margin-bottom:-80px ;
      transition: 0.3s color ease;
      @media (max-width: 700px) {
      margin-left: initial;
      margin-bottom: initial;
      
      }
      @media (max-width: 1200px) {
      margin-left: initial;
      margin-top: 25px;
    }
    @media (max-width: 1600px) {
      margin-left: 0px;
    }
      .icon {
        width: 14px;
        height: auto;
      }
      p {
        font-size: 14px;
        margin-left: 8px;
      }
      &:hover {
                color: #1eb8b8;
              }
    }

footer {
  a{
    color: red;
    text-decoration: none;
  }

  margin-top: auto;
  padding: 100px 25px;
  background-color: black;;
  .container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    @media (min-width: 800px) {
      flex-direction: row;
      gap: 0px;
    }
    > div {
      display: flex;
      flex: 1;
    }
    .left {
      gap: 32px;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (min-width: 800px) {
        flex-direction: row;
        align-items: initial;
        gap: 0;
        display: none;
      }
      .header {
        text-align: center;
        font-size: 24px;
        color: #fff;
        margin-bottom: 16px;
        text-decoration: none;
        font-weight: 600;
        @media (min-width: 800px) {
          text-align: initial;
        }
      }
      ul {
        gap: 16px;
        list-style: none;
        display: flex;
      }
    }
    .right {
      gap: 32px;
      color: #fff;
      align-items: center;
      flex-direction: column;
      margin-right: 150px;
      @media (max-width: 800px) {
        align-items: center;
        gap: 0;
        margin-right: initial;
      }
    }
    p {
      margin-top: auto;
    }
  }
}

@media  screen and (max-width: 480px) {
  footer{
    padding-top: 90em
  };
}

@media screen and (max-width: 430px) {
  .right{
    margin-left: 50px;
  }
  footer{
    padding-top: 90em
  };
}
</style>